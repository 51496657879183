<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';

export default {
    extends: BaseVue,
    data(){
        return {
            row: false,
        }
    },
    mounted() {
        this.$set(this.$root, 'page', this)
    },
};
</script>

<template>
  <div class="content-wrap pad_top_0">
    <Component404></Component404>
  </div>
</template>